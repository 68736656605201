import { createRouter, createWebHistory } from 'vue-router'

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'default-layout',
      component: () => import('@front/layouts/DefaultLayout.vue'),
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('@front/components/pages/home/PageHome.vue'),
        },
        {
          path: '/partners',
          name: 'partners',
          component: () => import('@front/components/pages/partners/PagePartners.vue'),
        },
        {
          path: '/customers',
          name: 'customers',
          component: () => import('@front/components/pages/customers/PageCustomers.vue'),
        },
        {
          path: '/faq',
          name: 'faq',
          component: () => import('@front/components/pages/faq/PageFAQ.vue'),
        },
        {
          path: '/travellers',
          name: 'travellers',
          component: () => import('@front/components/pages/travellers/PageTravellers.vue'),
        },
        {
          path: '/professionals',
          name: 'professionals',
          component: () => import('@front/components/pages/professionals/PageProfessionals.vue'),
        },
        {
          path: '/legal-notice',
          name: 'legal-notice',
          component: () => import('@front/components/pages/legal-notice/PageLegalNotice.vue'),
        },
        {
          path: '/privacy-policy',
          name: 'privacy-policy',
          component: () => import('@front/components/pages/privacy-policy/PagePrivacyPolicy.vue'),
        },
        {
          path: '/terms-condition',
          name: 'terms-condition',
          component: () => import('@front/components/pages/terms/PageTerms.vue'),
        },
        {
          path: '/account-deletion',
          name: 'account-deletion',
          component: () => import('@front/components/pages/account-deletion/PageAccountDeletion.vue'),
        },
        {
          path: '/cookies',
          name: 'cookies',
          component: () => import('@front/components/pages/cookies/PageCookies.vue'),
        },
        {
          path: '/contact/support-request/:uuid',
          name: 'support-request',
          component: () => import('@front/components/pages/support-request/PageSupportRequest.vue'),
        },
      ],
    },
    {
      path: '/broadcast/:uuid',
      name: 'broadcast-layout',
      component: () => import('@front/layouts/BroadcastLayout.vue'),
      children: [
        {
          path: '/broadcast/:uuid',
          name: 'broadcast',
          component: () => import('@front/components/pages/broadcast/PageBroadcast.vue'),
        },
      ],
    },
  ],
  scrollBehavior(to) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({ el: to.hash })
        }
        resolve({ top: 0 })
      }, 300)
    })
  },
})
