import { createStore } from 'vuex'

import flashes from '@shared/store/modules/flashes'
import locales from '@shared/store/modules/locales'

export default createStore({
  state: {
    appName: 'front',
  },
  modules: {
    flashes,
    locales,
  },
})
